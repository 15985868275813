import { Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import { ReferenceOpenContext } from "../../App";
import Animation from "../../animation/Animation";

export default function NavbarOwn() {
  const { t } = useTranslation();
  const setReferenceOpen = useContext(ReferenceOpenContext)[1];

  return (
    <>
      <nav className="mbookmin:container mx-auto xs:my-4">
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <div className="flex flex-row items-center justify-between mx-6 my-0 md:my-4">
                <div>
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <Animation direction="top">
                      <img
                        src={process.env.PUBLIC_URL + "/webby-logo.svg"}
                        className="h-14 md:h-12"
                        alt="Webby logo"
                        title="Webby"
                      />
                    </Animation>
                  </a>
                </div>
                <div className="hidden md:block items-center mt-2">
                  <button
                    onClick={() => setReferenceOpen(true)}
                    className="inline-flex sm:mx-4 paragraph p-default"
                  >
                    <Animation direction="top">{t("references")}</Animation>
                  </button>

                  <a
                    href={process.env.PUBLIC_URL + "/#about_us"}
                    className="inline-flex sm:mx-4 paragraph p-default"
                  >
                    <Animation direction="top" delay={150}>
                      {t("about_us")}
                    </Animation>
                  </a>

                  {/*<a
                  href={process.env.PUBLIC_URL + "/webbyverse"}
                  className="hidden sm:inline-flex sm:mx-4 paragraph p-default"
                >
                  {" "}
                  <Animation direction="top" delay={250}>
                    {t("webbyverse")}
                  </Animation>
                </a>*/}

                  <a
                    href={process.env.PUBLIC_URL + "/#contact_us"}
                    className="inline-flex sm:mx-4  py-1 paragraph p-default text-p300"
                  >
                    <Animation direction="top" delay={350}>
                      <div className="rounded-full bg-p50 sm:px-6">
                        {t("contact_us")}
                      </div>
                    </Animation>
                  </a>
                </div>
                <div className="-mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Disclosure.Panel className="md:hidden">
                  <div className="pt-2 pb-3 space-y-1">
                    <Disclosure.Button
                      onClick={() => setReferenceOpen(true)}
                      as="a"
                      href="#references"
                      className="paragraph bg-indigo-50 border-p300 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-bas hover:bg-p50"
                    >
                      {t("references")}
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href={process.env.PUBLIC_URL + "/#about_us"}
                      className="paragraph bg-indigo-50 border-p300 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base hover:bg-p50"
                    >
                      {t("about_us")}
                    </Disclosure.Button>
                    {/*<Disclosure.Button
                    as="a"
                    href={process.env.PUBLIC_URL + "/webbyverse"}
                    className="paragraph bg-indigo-50 border-p300 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base hover:bg-p50"
                  >
                    {t("webbyverse")}
                  </Disclosure.Button>*/}
                    <Disclosure.Button
                      as="a"
                      href={process.env.PUBLIC_URL + "/#contact_us"}
                      className="paragraph bg-indigo-50 border-p300 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base hover:bg-p50"
                    >
                      {t("contact_us")}
                    </Disclosure.Button>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </nav>
    </>
  );
}
