import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReferenceCard(props) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const active = (b, p) => {
    if (b === p) {
      return "ring ring-4 ring-s300";
    }
  };

  return (
    <>
      <div className="arrow my-auto justify-between">
        <button
          className="w-[40px] h-auto"
          onClick={() => {
            if (index !== 0) {
              document.getElementById("right-arrow").style.opacity = 1;
              setIndex(index - 1);
            }
            if (index === 1) {
              document.getElementById("left-arrow").style.opacity = 0.4;
            }
          }}
        >
          <ChevronLeftIcon
            id="left-arrow"
            className="opacity-[0.4] fill-n900"
          />
        </button>
        <button
          className="w-[40px] h-auto"
          onClick={() => {
            if (index !== props.array.length - 1) {
              document.getElementById("left-arrow").style.opacity = 1;
              setIndex(index + 1);
            }
            if (index === props.array.length - 2) {
              document.getElementById("right-arrow").style.opacity = 0.4;
            }
          }}
        >
          <ChevronRightIcon id="right-arrow" className="fill-n900" />
        </button>
      </div>
      <div className="max-h-[80vh] flex items-center justify-center">
        <img
          src={props.array[index].imgurl}
          loading="lazy"
          alt={t("references")}
          title={t("open")}
          className="mx-auto w-auto max-h-[80vh] object-cover rounded-[10px] fixed blur"
          width="auto"
          height="100%"
        />
        <a
          href={props.array[index].imgref}
          target="_blank"
          rel="noreferrer"
          className="z-30"
        >
          <img
            src={props.array[index].imgurl}
            loading="lazy"
            title={t("open")}
            alt={t("references")}
            className="mx-auto w-auto max-h-[80vh] object-cover rounded-[10px]"
            width="auto"
            height="100%"
          />
        </a>
      </div>

      <div className="pag z-30">
        <div className="max-w-[80%] flex justify-center gap-4 mx-auto">
          {props.array.map((p, i) => (
            <div
              key={i}
              className={`relative pagbg rounded-[15px] cursor-pointer ${active(
                index,
                p.index
              )}`}
              onClick={() => {
                setIndex(p.index);
                if (p.index === 0) {
                  document.getElementById("left-arrow").style.opacity = 0.4;
                } else {
                  document.getElementById("left-arrow").style.opacity = 1;
                }
                if (p.index === props.array.length - 1) {
                  document.getElementById("right-arrow").style.opacity = 0.4;
                } else {
                  document.getElementById("right-arrow").style.opacity = 1;
                }
              }}
            >
              <div className="p-[10px] relative">
                <img
                  src={p.imgurl}
                  loading="lazy"
                  title={p.imgalt}
                  alt={t("references")}
                  className="z-40 w-auto max-h-[100px] rounded-[10px]"
                  width="auto"
                  height="100%"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
