import { useTranslation } from "react-i18next";

export default function useReferenceImages() {
  const { t } = useTranslation();

  return [
    {
      index: 0,
      imgurl: "../../../images/exclusivechef.webp",
      imgalt: "exclusivechef",
      imgtitle: t("website_desing_development"),
      imgsite: false,
      imgref: "https://exclusivechef.hu/",
    },
    {
      index: 1,
      imgurl: "../../../images/sparkleapi.webp",
      imgalt: "sparkleapi",
      imgtitle: t("website_desing_development"),
      imgsite: false,
      imgref: "https://sparkleapi.com/",
    },
    {
      index: 2,
      imgurl: "../../../images/curriculite.webp",
      imgalt: "curriculite",
      imgtitle: t("website_desing_development"),
      imgsite: true,
      imgref: "https://curriculite.com/",
    },
    {
      index: 3,
      imgurl: "../../../images/interft.webp",
      imgalt: "interft",
      imgtitle: t("website_desing_development"),
      imgsite: true,
      imgref: "https://interft.hu/",
    },
    {
      index: 4,
      imgurl: "../../../images/goldatti.webp",
      imgalt: "goldatti",
      imgtitle: t("website_desing_development"),
      imgsite: true,
      imgref: "https://demo.attigold.hu/manage/orders",
    },
    {
      index: 5,
      imgurl: "../../../images/travel.webp",
      imgalt: "travel",
      imgtitle: t("website_desing_development"),
      imgsite: false,
    },
    {
      index: 6,
      imgurl: "../../../images/food.webp",
      imgalt: "food",
      imgtitle: t("website_desing_development"),
      imgsite: false,
    },
    {
      index: 7,
      imgurl: "../../../images/goldatti.webp",
      imgalt: "lawyer",
      imgtitle: t("website_desing_development"),
      imgsite: false,
    },
    {
      index: 8,
      imgurl: "../../../images/chatapp.webp",
      imgalt: "chatapp",
      imgtitle: t("website_desing_development"),
      imgsite: false,
    },
  ];
}
