import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useContext } from "react";

import BlobSvgA from "../../components/BlobSvgA";
import DeveloperCard from "../../components/DeveloperCard";
import Animation from "../../animation/Animation";
import BlobSvgB from "../../components/BlobSvgB";
import useMink from "../../data/useMink";
import { ReferenceOpenContext } from "../../App";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../App.css";

export default function AboutUs() {
  const { t } = useTranslation();
  const mink = useMink();
  const setReferenceOpen = useContext(ReferenceOpenContext)[1];

  return (
    <section>
      <div className="sm:hidden">
        <BlobSvgA blob="blob" />
      </div>
      <div className="hidden sm:table">
        <BlobSvgA blob="blob4" />
        <BlobSvgB blob="blob3" />
      </div>
      <section id="about_us">
        <Animation direction="bottom">
          <h2 className="mt-[50px] text-smh2 xs:text-h2size text-center header font-bold px-[30px]">
            {t("aboutustitle")}
          </h2>
        </Animation>
        <Animation direction="bottom">
          <p className=" text-pdefault xs:text-plarge text-center mt-5 max-w-[820px] px-[25px] paragraph mx-auto ">
            {t("aboutusdesc")}
          </p>
        </Animation>
        <Animation direction="bottom">
          <div className="flex justify-center my-8">
            <button
              onClick={() => setReferenceOpen(true)}
              className="hover:-translate-y-1.5 hover:shadow-[rgba(95,_95,_255,_0.6)_0px_10px_10px] transition ease-in-out duration-300 mobile:text-smplarge px-7 py-3 text-n0 text-plarge rounded-lg paragraph gradient"
            >
              {t("references")}
            </button>
          </div>
        </Animation>
      </section>
      <div className="flex-row justify-center mt-5 hidden md:flex max-w-[1000px] mx-auto mb-[50px]">
        <div className="inline-flex">
          {mink.map((data, i) => {
            return (
              <Animation direction="bottom" delay={i * 100} key={i}>
                <DeveloperCard
                  name={data.name}
                  title={data.title}
                  imgsrc={data.imgsrc}
                />
              </Animation>
            );
          })}
        </div>
      </div>
      <div className="md:hidden">
        <Animation direction="bottom">
          <Swiper
            grabCursor={true}
            slidesPerView={1}
            spaceBetween={40}
            centeredSlides={true}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              500: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            className="mb-[50px]"
          >
            {mink.map((data, i) => {
              return (
                <SwiperSlide className="swiper-wrapper-about" key={i}>
                  <DeveloperCard
                    name={data.name}
                    title={data.title}
                    imgsrc={data.imgsrc}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Animation>
      </div>
    </section>
  );
}
