import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function CookieBanner() {
  const { t } = useTranslation();
  const [cookies, setCookies] = useState(localStorage.getItem("cookies"));

  useEffect(() => {
    localStorage.setItem("cookies", cookies);
  }, [cookies]);

  return (
    <>
      <div
        className={`${
          cookies
            ? "hidden"
            : "fixed bottom-0 left-0 z-10 w-full py-4 px-8 md:p-4 bg-n0 shadow md:flex md:items-center md:justify-between md:p-6"
        }`}
      >
        <span className="text-pdefault text-gray-500 dark:text-gray-400 max-w-5xl">
          {t("cookie_summary")}
          <a
            href={process.env.PUBLIC_URL + "/cookie-policy"}
            className="hover:underline font-bold"
          >
            {t("cookie_policy")}
          </a>
          .
        </span>
        <ul className="flex items-center justify-center md:mx-4 mt-4 text-sm font-medium text-gray-500 sm:mt-0">
          {/* <li>
                        <button className="hover:underline me-4 md:me-6" onClick={()=>setCookies(!cookies)}>{t("reject_non_essential")}</button>
                    </li> */}
          <li>
            <button
              className="transition ease-in-out duration-300 mobile:text-smplarge px-6 py-2 text-n0 text-plarge rounded-lg paragraph gradient"
              onClick={() => setCookies(!cookies)}
            >
              {t("accept_cookies")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
