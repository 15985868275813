import { useTranslation } from "react-i18next";

export default function useFormParts() {
  const { t } = useTranslation();

  return [
    {
      label: t("name"),
      id: "name",
      placeholder: t("name_placeholder"),
      pattern:
        "([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9. -_s]+)",
      type: "text",
      inputmode: "text",
      errormessage: t("name_error"),
    },
    {
      label: t("email"),
      id: "email",
      placeholder: t("email_placeholder"),
      pattern: "([A-Za-z0-9][._]?)+[A-Za-z0-9]@[A-Za-z0-9]+(.[A-Za-z0-9]+)+",
      type: "email",
      inputmode: "text",
      errormessage: t("email_error"),
    },
    {
      label: t("phone"),
      id: "phone",
      placeholder: t("phone_placeholder"),
      pattern: "([+]?[0-9 _s]+)",
      type: "text",
      inputmode: "numeric",
      errormessage: t("phone_error"),
    },
  ];
}
