import {
  ClipboardDocumentListIcon,
  PaintBrushIcon,
  CodeBracketSquareIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";

export default function ProgressCard(props) {
  function renderSwitch(params) {
    switch (params) {
      case "ClipboardDocumentListIcon":
        return (
          <ClipboardDocumentListIcon className="w-[20px] h-auto mr-[5px] text-s300 mb-[10px]" />
        );
      case "PaintBrushIcon":
        return (
          <PaintBrushIcon className="w-[20px] h-auto mr-[5px] text-s300 mb-[10px]" />
        );
      case "CodeBracketSquareIcon":
        return (
          <CodeBracketSquareIcon className="w-[20px] h-auto mr-[5px] text-s300 mb-[10px]" />
        );
      case "ChatBubbleLeftRightIcon":
        return (
          <ChatBubbleLeftRightIcon className="w-[20px] h-auto mr-[5px] text-s300 mb-[10px]" />
        );
      case "CheckBadgeIcon":
        return (
          <CheckBadgeIcon className="w-[20px] h-auto mr-[5px] text-s300 mb-[10px]" />
        );
      default:
    }
  }

  return (
    <>
      <div className={`w-[33%] ${props.className}`}>
        <div className="w-fit mx-auto">
          {props.lineDirection === "top" ? (
            <svg width="12" height="41" viewBox="0 0 12 41" fill="none">
              <path
                d="M5 31L0.226497 41H11.7735L7 31H5ZM5 0V32H7V0H5Z"
                fill="#3737D5"
              />
            </svg>
          ) : null}
        </div>
        <div
          className={`w-full ${props.styleheight} h-[160px] bg-n0 rounded-[20px] shadow-[0_4px_30px_0px_rgba(0,0,0,0.10)] flex`}
        >
          <div
            className={`h-full progressgradient min-w-[70px] xs:min-w-[100px] md:min-w-[70px] xl:min-w-[100px] ${props.stylewidth} rounded-l-[20px] flex justify-center items-center`}
          >
            <p className="text-[40px] paragraph text-n0 font-semibold">
              {props.n}
            </p>
          </div>
          <div className="px-[15px] pt-[25px]">
            <div className="flex">
              {renderSwitch(props.icon)}
              <h3 className="font-bold xsmobile:text-pdefault sm:text-plarge header mb-[10px]">
                {props.header}
              </h3>
            </div>
            <p className="paragraph sm:text-pdefault xsmobile:text-pdefault">
              {props.paragraph}
            </p>
          </div>
        </div>
        <div className="w-fit mx-auto">
          {props.lineDirection === "bottom" ? (
            <svg width="12" height="41" viewBox="0 0 12 41" fill="none">
              <path
                d="M7 10L11.7735 0H0.226497L5 10H7ZM5 9V41H7V9H5Z"
                fill="#3737D5"
              />
            </svg>
          ) : null}
        </div>
      </div>
    </>
  );
}
