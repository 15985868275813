import { useTranslation } from "react-i18next";
import Animation from "../../animation/Animation";
import EmailContact from "../../components/EmailContact";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-n900">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <Animation direction="bottom">
            <a href={process.env.PUBLIC_URL + "/#"}>
              <div className="flex flex-row justify-center mb-4">
                <img
                  src={process.env.PUBLIC_URL + "/webby-logo.svg"}
                  className="max-h-16"
                  alt="Webby logo"
                  title="Webby logo"
                />
              </div>
            </a>
          </Animation>

          <nav
            className="mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <Animation direction="bottom">
              <div className="px-5 py-2">
                <a
                  href={process.env.PUBLIC_URL + "/privacy"}
                  className="text-base text-n0"
                >
                  {t("privacy_policy")}
                </a>
              </div>
            </Animation>

            <Animation direction="bottom">
              <div className="px-5 py-2">
                <a
                  href={process.env.PUBLIC_URL + "/cookies"}
                  className="text-base text-n0"
                >
                  {t("cookie_policy")}
                </a>
              </div>
            </Animation>
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            <Animation direction="bottom">
              <a
                href="https://www.facebook.com/webbydevs/"
                className="text-n0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Facebook</span>
                <img
                  src={process.env.PUBLIC_URL + "/facebook.svg"}
                  className="h-6 w-6"
                  title="Facebook"
                  alt="Facebook"
                />
              </a>
            </Animation>

            <Animation direction="bottom">
              <a
                href="https://www.instagram.com/webbydevs/"
                className="text-n0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Instagram</span>
                <img
                  src={process.env.PUBLIC_URL + "/instagram.svg"}
                  className="hh-6 w-6"
                  title="Instagram"
                  alt="Instagram"
                />
              </a>
            </Animation>

            <Animation direction="bottom">
              <a
                href="https://www.linkedin.com/company/webbydevs/"
                className="text-n0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Linkedin</span>
                <img
                  src={process.env.PUBLIC_URL + "/linkedin.svg"}
                  className="h-6 w-6"
                  title="Linkedin"
                  alt="Linkedin"
                />
              </a>
            </Animation>
          </div>

          <Animation direction="bottom">
            <EmailContact className="flex flex-row justify-center items-center mt-8 gap-2 text-n0 font-bold" />
            <p className="mt-8 text-center text-base text-n0">
              &copy; {t("copyright")}
            </p>
          </Animation>
        </div>
      </footer>
    </>
  );
}
