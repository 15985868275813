import { useTranslation } from "react-i18next";

export default function useMink() {
  const { t } = useTranslation();

  return [
    {
      name: t("sarkozi_balazs"),
      title: t("devops_title"),
      imgsrc: "/images/balazs_sarkozi.webp",
    },
    {
      name: t("nemeth_szilveszter"),
      title: t("uidesign_title"),
      imgsrc: "/images/szilveszter_nemeth.webp",
    },
    {
      name: t("biro_balint"),
      title: t("frontend_title"),
      imgsrc: "/images/balint_biro.webp",
    },
    {
      name: t("nyikon_zoltan"),
      title: t("backend_title"),
      imgsrc: "/images/zoltan_nyikon.webp",
    },
  ];
}
