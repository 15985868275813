import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import Infobar from "../Navbar/Infobar";
import NavbarOwn from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Services from "./Services";
import AboutUs from "./AboutUs";
import Welcome from "./Welcome";
import Progress from "./Progress";
import ContactUs from "./ContactUs";
import References from "./References";
import renderStructuredData from "../../components/StructuredData";
import { createContext, useState } from "react";

export const ReferenceOpenContext = createContext(false);

export default function Landing() {
  const [referenceOpen, setReferenceOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ReferenceOpenContext.Provider value={[referenceOpen, setReferenceOpen]}>
        <Helmet>
          <title>Webby - {t("seotitle")}</title>
          <link rel="canonical" href={process.env.PUBLIC_URL + "/"}></link>
          {renderStructuredData()}
        </Helmet>
        <Infobar />
        <NavbarOwn />
        <Welcome />
        <Services />
        <AboutUs />
        <Progress />
        <ContactUs />
        <Footer />
        <References open={referenceOpen} setOpen={setReferenceOpen} />
      </ReferenceOpenContext.Provider>
    </>
  );
}
