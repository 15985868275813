import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function Error404() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>404 - {t("page_not_found")}</title>
      </Helmet>
      <section className="grid place-items-center dark:bg-slate-900 px-6 py-48 lg:px-8">
        <div className="text-center">
          <p className="font-semibold text-indigo-600 text-h1size">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-5xl">
            {t("page_not_found")}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
            {t("page_not_found_reason")}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={process.env.PUBLIC_URL + "/"}
              className="transition ease-in-out hover:-translate-y-1.5 hover:shadow-[rgba(95,_95,_255,_0.6)_0px_50px_90px] duration-300 mobile:text-smplarge mobile:py-2.5 mobile:px-[15px] py-3 px-[30px] text-n0 text-plarge rounded-lg paragraph gradient"
            >
              {t("back_to_home")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
