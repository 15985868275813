import Infobar from "../Navbar/Infobar";
import NavbarOwn from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function CookiePolicy() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Webby - {t("cookie_policy")}</title>
      </Helmet>
      <Infobar />
      <NavbarOwn />
      <section className="container mx-auto px-4 max-w-3xl my-16">
        <header className="mb-4 lg:mb-6">
          <h1 className="text-h3size mb-2 text-center font-extrabold leading-tight text-gray-900">
            {t("cookie_policy")}
          </h1>
          <p className="font-thin">{t("cookie_policy_sidenote")}</p>
        </header>
        <p className="font-semibold my-2">{t("what_are_cookies")}</p>
        <p>{t("what_are_cookies_paragraph")}</p>
        <p className="font-semibold my-2">{t("how_we_use_cookies")}</p>
        <p>{t("how_we_use_cookies_paragraph")}</p>
        <p className="font-semibold my-2">{t("disabling_cookies")}</p>
        <p>{t("disabling_cookies_paragraph")}</p>
        <p className="font-semibold my-2">{t("cookies_we_set")}</p>
        <ul className="max-w-2xl ml-8 space-y-2 text-gray-500 list-disc list-inside">
          <li>
            {t("email_newsletter_related_cookies")}
            <p className="ml-4 my-2">
              {t("email_newsletter_related_cookies_paragraph")}
            </p>
          </li>
          <li>
            {t("forms_related_cookies")}
            <p className="ml-4 my-2">{t("forms_related_cookies_paragraph")}</p>
          </li>
          <li>
            {t("site_preferences_cookies")}
            <p className="ml-4 my-2">
              {t("site_preferences_cookies_paragraph")}
            </p>
          </li>
        </ul>
        <p className="font-semibold my-2">{t("more_information")}</p>
        <p className="my-2">{t("more_information_paragraph_1")}</p>
        <p className="my-2">
          {t("more_information_paragraph_2")}
          <a
            href="https://www.cloudflare.com/learning/privacy/what-are-cookies/"
            target="_blank"
            rel="noreferrer"
            className="hover:underline font-bold"
          >
            {t("article")}
          </a>
        </p>
        <p className="my-2">{t("more_information_paragraph_3")}</p>
        <ul className="max-w-2xl ml-8 space-y-2 text-gray-500 list-disc list-inside">
          <li>{t("webby_email")}</li>
        </ul>
      </section>
      <Footer />
    </>
  );
}
