import { useTranslation } from "react-i18next";
import { useContext } from "react";

import { ReferenceOpenContext } from "../../App";
import Animation from "../../animation/Animation";
import BlobSvgA from "../../components/BlobSvgA";
import BlobSvgB from "../../components/BlobSvgB";
import AnimatedTitle from "../../components/AnimatedTitle";
import DeviceSwiper from "../../components/DeviceSwiper";
import useLaptopImgs from "../../data/useLaptopImgs";
import useMobileImgs from "../../data/useMobileImgs";

export default function Welcome() {
  const { t } = useTranslation();
  const setReferenceOpen = useContext(ReferenceOpenContext)[1];
  const laptopData = useLaptopImgs();
  const phoneData = useMobileImgs();

  return (
    <section>
      <div className="hidden sm:table">
        <BlobSvgB blob="blob4" />
      </div>
      <BlobSvgA blob="blob2" />
      <BlobSvgA blob="blob3" />
      <div className="mbookmin:container mbookmin:px-20 flex tablet:flex-col mbook:mr-0 mbook:ml-20 ml-auto mr-auto tablet:gap-y-32 mobile:mx-3 tablet:mx-20 md:mb-[144px]">
        <div className="flex tablet:flex-col tablet:gap-8 flex-row items-center justify-between w-full">
          <div>
            <div className="min-h-[220px] mobile:min-h-[200px] tablet:min-h-[240px] md:min-h-[240px] lg:min-h-[230px] xl:min-h-[150px] 3xl:min-h-[100px] tablet:items-center flex mobile:pb-8 tablet:justify-center ">
              <Animation direction="left">
                <AnimatedTitle />
              </Animation>
            </div>
            <Animation direction="left" delay={150}>
              <div className="max-w-3xl">
                <p className="mobile:text-smplarge text-plarge paragraph mb-8 tablet:text-center">
                  {t("about_us_short")}
                </p>
              </div>
            </Animation>
            <Animation direction="left" delay={250}>
              <div className="flex tablet:justify-center">
                <a
                  href={process.env.PUBLIC_URL + "/#contact_us"}
                  className="hover:-translate-y-1.5 hover:shadow-[rgba(95,_95,_255,_0.6)_0px_50px_90px] transition ease-in-out duration-300 mobile:text-smplarge mobile:py-2.5 mobile:px-[15px] py-5 px-[30px] text-n0 text-plarge rounded-lg paragraph gradient"
                  style={{
                    WebkitTouchCallout: "none",
                  }}
                >
                  {t("get_offer")}
                </a>
              </div>
            </Animation>
          </div>
          <div onClick={() => setReferenceOpen(true)}>
            <Animation direction="right" delay={100}>
              <DeviceSwiper data={phoneData} className="hidden tablet:flex" />
              <DeviceSwiper
                data={laptopData}
                className="tablet:hidden flex relative right-[-100px]"
              />
            </Animation>
          </div>
        </div>
      </div>
    </section>
  );
}
