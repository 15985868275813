export default function DeveloperCard(props) {
  return (
    <>
      <div className="overflow-hidden mx-5">
        <img
          className="max-h-[350px] rounded-lg mx-auto"
          loading="lazy"
          src={props.imgsrc}
          alt={props.name}
          title={props.name}
        />
        <h4 className="text-center paragraph font-bold text-plarge mobile:text-smpplarge mt-3">
          {props.name}
        </h4>
        <p className="text-center paragraph text-pdefault mobile:text-smpdefault">
          {props.title}
        </p>
      </div>
    </>
  );
}
