export default function useInfoBarLangs() {
  return [
    {
      value: "hu",
      name: "Magyar",
      label: (
        <img
          src={process.env.PUBLIC_URL + "/HUN.svg"}
          className="inline-flex h-[18px] mr-2 mb-1"
          alt="Magyar"
          title="Magyar"
        />
      ),
    },
    {
      value: "en",
      name: "English",
      label: (
        <img
          src={process.env.PUBLIC_URL + "/UK.svg"}
          className="inline-flex h-[18px] mr-2 mb-1"
          alt="English"
          title="English"
        />
      ),
    },
    /* {
      value: "de",
      name: "Deutsch",
      label: (
        <img
          src={process.env.PUBLIC_URL + "/DE.svg"}
          className="inline-flex h-[18px] mr-2 mb-1"
          alt="Deutsch"
          title="Deutsch"
        />
      ),
    }, */
  ];
}
