export default function BlobSvgB(props) {
  return (
    <>
      <div className="container mx-auto relative">
        <div className={props.blob}>
          <svg
            width="456"
            height="385"
            viewBox="0 0 456 385"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M410 111.5C352.409 20.9745 257.199 -20.5554 154.5 10.5C66.5348 37.0999 0.13478 96.1011 0 188C-0.149497 289.934 91.0328 341.142 189.5 367.5C283.43 392.643 383.249 399.046 433 315.5C474.02 246.617 453.034 179.143 410 111.5Z"
              fill="#3737d566"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
