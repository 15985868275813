export default function ServiceCard(props) {
  const activeClass = props.active;
  return (
    <>
      <div
        className={`shadow-[0_4px_30px_0px_rgba(0,0,0,0.10)] rounded-[20px] pt-[70px] pb-[130px] px-[18px] bg-n0 mx-auto flex flex-col h-full max-w-[350px] swiper:mx-3 ${activeClass}`}
      >
        <img
          className="h-[100px] w-[100px] mx-auto"
          src={props.imgurl}
          alt={props.imgalt}
          title={props.imgtitle}
        />
        <h3 className="text-center text-p500 mb-[20px] mt-[15px] mobile:text-smh3 text-h5size header font-bold">
          {props.header}
        </h3>
        <p className="text-plarge paragraph text-center text-n900">
          {props.paragraph}
        </p>
      </div>
    </>
  );
}
