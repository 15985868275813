export default function useInfoBarSocials() {
  return [
    {
      href: "https://www.facebook.com/webbydevs/",
      src: "/facebook.svg",
      title: "Facebook",
    },
    {
      href: "https://www.instagram.com/webbydevs/",
      src: "/instagram.svg",
      title: "Instagram",
    },
    {
      href: "https://www.linkedin.com/company/webbydevs/",
      src: "/linkedin.svg",
      title: "Linkedin",
    },
  ];
}
