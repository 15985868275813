export default function FormIput(props) {
  return (
    <>
      <div className="mt-3">
        <label className="text-smplarge md:text-plarge">{props.label}</label>
        <label htmlFor={props.id}>
          <input
            type={props.type}
            required
            inputMode={props.inputmode}
            name={props.id}
            id={props.id}
            placeholder={props.placeholder}
            pattern={props.pattern}
            className="text-smplarge md:text-pdefault paragraph peer border-0 bg-p50 rounded-2xl placeholder-p300 w-full invalid:[&:not(:placeholder-shown):not(:focus)]:border-w300 invalid:[&:not(:placeholder-shown):not(:focus)]:border-2"
          />
          <span className="text-smplarge md:text-pdefault mt-2 hidden text-sm paragraph text-w300 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
            {props.errormessage}
          </span>
        </label>
      </div>
    </>
  );
}
