import Infobar from "../Navbar/Infobar";
import NavbarOwn from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <>
        <Helmet>
          <title>Webby - {t("privacy_policy")}</title>
        </Helmet>
      <Infobar />
      <NavbarOwn />
      <section className="container mx-auto px-4 max-w-3xl my-16">
        <header className="mb-4 lg:mb-6">
          <h1 className="text-h3size mb-2 text-center font-extrabold leading-tight text-gray-900">
            {t("privacy_policy")}
          </h1>
          <p className="font-thin">{t("privacy_policy_sidenote")}</p>
        </header>
        <p className="font-semibold my-2">{t("consent")}</p>
        <p>{t("consent_paragraph")}</p>
        <p className="font-semibold my-2">{t("information_we_collect")}</p>
        <p>{t("information_we_collect_paragraph")}</p>
        <p className="font-semibold my-2">{t("how_we_use_information")}</p>
        <p>{t("how_we_use_information_paragraph")}</p>
        <ul className="max-w-2xl ml-8 space-y-2 text-gray-500 list-disc list-inside">
          <li>{t("how_we_use_information_clause_1")}</li>
          <li>{t("how_we_use_information_clause_2")}</li>
          <li>{t("how_we_use_information_clause_3")}</li>
          <li>{t("how_we_use_information_clause_4")}</li>
          <li>{t("how_we_use_information_clause_5")}</li>
          <li>{t("how_we_use_information_clause_6")}</li>
          <li>{t("how_we_use_information_clause_7")}</li>
        </ul>
        <p className="font-semibold my-2">{t("log_files")}</p>
        <p>{t("log_files_paragraph")}</p>
        <p className="font-semibold my-2">
          {t("advertising_partners_privacy_policies")}
        </p>
        <p>{t("advertising_partners_privacy_policies_paragraph")}</p>
        <p className="font-semibold my-2">
          {t("third_party_privacy_policies")}
        </p>
        <p>{t("third_party_privacy_policies_paragraph")}</p>
        <p className="font-semibold my-2">{t("gdpr_data_protection_rights")}</p>
        <p>{t("gdpr_data_protection_rights_paragraph")}</p>
        <p className="font-semibold my-2">{t("children_information")}</p>
        <p>{t("children_information_paragraph")}</p>
      </section>
      <Footer />
    </>
  );
}
