export default function SocialItem(props) {
  return (
    <>
      <a href={props.href} target="_blank" rel="noreferrer">
        <img
          src={props.src}
          className="h-[18px] mx-2"
          title={props.title}
          alt={props.title}
        />
      </a>
    </>
  );
}
