import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/main/Landing";
import Error404 from "./pages/error/404";
import { initReactI18next } from "react-i18next";
import CookieBanner from "./components/CookieBanner";
import i18n from "i18next";
import translationEN from "./locales/en.json";
import translationHU from "./locales/hu.json";
import translationDE from "./locales/de.json";
import CookiePolicy from "./pages/policies/CookiePolicy";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import ScrollToTop from "react-scroll-to-top";
import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import { HelmetProvider } from "react-helmet-async";
import { createContext, useState } from "react";
import References from "./pages/main/References";

export const ReferenceOpenContext = createContext(false);

const resources = {
  en: {
    translation: translationEN,
  },
  hu: {
    translation: translationHU,
  },
  de: {
    translation: translationDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "hu",
  fallbackLng: "hu",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const [refenenceOpen, setReferenceOpen] = useState(false);

  if (localStorage.getItem("lang") != null) {
    i18n.changeLanguage(localStorage.getItem("lang"));
  }
  return (
    <HelmetProvider>
      <ReferenceOpenContext.Provider value={[refenenceOpen, setReferenceOpen]}>
        <div className="App overflow-hidden">
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/cookies" element={<CookiePolicy />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Router>
          <CookieBanner />
          <ScrollToTop
            smooth
            className="hidden md:flex"
            component={
              <ArrowUpCircleIcon fill="#7A7AFF" className="h-10 w-10" />
            }
          />
        </div>
        <References open={refenenceOpen} setOpen={setReferenceOpen} />
      </ReferenceOpenContext.Provider>
    </HelmetProvider>
  );
}

export default App;
