import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Autoplay } from "swiper/modules";

export default function DeviceSwiper(props) {
  const { t } = useTranslation();

  return (
    <div
      className={`mt-8 mx-auto max-w-[250px] xs:max-w-[350px] h-auto 2xl:max-w-[800px] lg:max-w-[600px] md:max-w-[500px] transition ease-in-out hover:scale-105 duration-300 ${props.className}`}
    >
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={2000}
        observer={true}
        modules={[EffectFade, Autoplay]}
        spaceBetween={30}
        effect={"fade"}
        slidesPerView={1}
        loop={true}
        allowTouchMove={false}
      >
        {props.data.map((data, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="flex flex-col h-full w-full cursor-pointer">
                <img
                  src={`../../device-images/${data.image}`}
                  alt={t("device_swiper")}
                  title={t("open")}
                />
              </div>
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
