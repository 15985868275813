import { useEffect, useRef, useState, Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ReferenceCard from "../../components/ReferenceCard";
import useReferenceImages from "../../data/useReferenceImages";

import { ReferenceOpenContext } from "../../App";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function References() {
  const { t } = useTranslation();
  const images = useReferenceImages();
  const closeRef = useRef(null);
  const [referenceOpen, setReferenceOpen] = useContext(ReferenceOpenContext);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    const handleOrientationChange = () => {
      setTimeout(() => {
        setScreenHeight(window.innerHeight);
        setScreenWidth(window.innerWidth);
      }, 200);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  //Checks device type
  let deviceType = false;
  const ua = navigator.userAgent;
  if (
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    deviceType = true;
  }
  return referenceOpen ? (
    <section>
      <div className="popup z-10">
        <Transition appear show={referenceOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              ref={closeRef}
              className="hidden lg:table m-auto bg-n0 h-[95vh] w-[1400px] rounded-xl"
            >
              <div className="flex justify-between z-20">
                <div className="w-8 h-auto m-[15px]"></div>
                <div className="m-auto text-plarge paragraph text-center text-n900">
                  {t("references")}
                </div>
                <button
                  onClick={() => {
                    setReferenceOpen(false);
                  }}
                  className="w-8 h-auto m-[15px]"
                >
                  <XMarkIcon className="fill-n900" />
                </button>
              </div>

              <ReferenceCard array={images} />
            </div>
          </Transition.Child>
        </Transition>
      </div>

      <div className="lg:hidden popup-phone z-10">
        {screenHeight > screenWidth && deviceType ? (
          <div
            className="rotate-phone z-20 fade-item"
            style={{ animationDelay: `2s` }}
          >
            <div className="flex flex-col m-auto">
              <svg
                width="305"
                height="324"
                viewBox="0 0 305 324"
                fill="none"
                className="max-w-[150px] h-auto mx-auto"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M198.444 8.7471C189.143 13.1081 180.93 17.1761 180.194 17.7871C176.79 20.6121 178.683 23.3391 193.583 37.0761C208.494 50.8221 211.513 52.5961 214.656 49.4531C217.431 46.6781 215.94 43.5031 208.606 36.5661C200.333 28.7421 200.898 28.2291 212.946 32.6261C240.121 42.5441 263.331 63.8741 275.369 89.9931C278.424 96.6211 279.991 101.323 282.852 112.447C284.449 118.656 290.286 120.456 292.912 115.548C294.975 111.694 287.243 88.2991 279.563 75.1531C271.608 61.5371 256.624 45.3231 244.195 36.8831C235.411 30.9181 223.089 24.8381 213.601 21.7871C209.066 20.3291 205.319 18.6891 205.274 18.1441C205.228 17.5991 208.153 15.8621 211.774 14.2841C219.989 10.7041 221.856 9.0831 221.856 5.5331C221.856 2.7521 219.488 0.560107 216.672 0.735107C215.948 0.780107 207.746 4.3851 198.444 8.7471ZM11.3924 208.003C8.76938 209.921 9.90138 218.168 14.4804 230.501C23.7174 255.379 38.9924 274.86 60.5934 289.31C69.8254 295.486 83.0804 301.679 92.5524 304.243C96.0194 305.182 98.8564 306.252 98.8564 306.621C98.8564 306.99 95.4464 308.79 91.2784 310.622C87.1104 312.454 83.0604 314.592 82.2784 315.374C80.0424 317.61 80.5514 321.407 83.3304 323.228C85.7294 324.8 86.3594 324.592 104.039 316.394C114.068 311.743 122.725 307.223 123.276 306.348C123.929 305.313 123.944 303.885 123.318 302.256C122.038 298.925 94.3834 273.653 92.0184 273.653C89.6174 273.653 86.8564 276.733 86.8564 279.411C86.8564 280.762 89.5594 283.941 94.4224 288.311C98.5834 292.05 101.545 295.257 101.003 295.437C99.2934 296.007 84.6784 290.589 76.3564 286.299C49.6684 272.544 29.1614 247.138 21.7234 218.615C18.7314 207.141 16.1694 204.51 11.3924 208.003Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.87048 123.692C-1.94 115.882 -1.94 103.219 5.87048 95.4081L85.7736 15.505C93.584 7.69453 106.247 7.69454 114.058 15.505L298.613 200.06C306.423 207.87 306.423 220.534 298.613 228.344L218.71 308.247C210.899 316.058 198.236 316.058 190.425 308.247L5.87048 123.692ZM92.8446 29.6472L20.0126 102.479C16.1074 106.384 16.1074 112.716 20.0126 116.621L197.496 294.105C201.402 298.01 207.733 298.01 211.639 294.105L284.471 221.273C288.376 217.368 288.376 211.036 284.471 207.131L106.987 29.6472C103.082 25.7419 96.7499 25.7419 92.8446 29.6472Z"
                  fill="white"
                />
              </svg>
              <p className="font-bold paragraph text-n0 text-plarge mx-auto mt-[40px]">
                {t("rotate_screen")}
              </p>
            </div>
          </div>
        ) : null}
        <div className="flex justify-end xicon z-20">
          <button
            onClick={() => {
              setReferenceOpen(false);
            }}
            className="w-8 h-auto m-[15px]"
          >
            <XMarkIcon className="fill-n0" />
          </button>
        </div>
        <Swiper
          lazy="true"
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {images.map((p, i) => (
            <SwiperSlide id="notop" key={i}>
              <div className="h-[100vh] flex items-center justify-center">
                <a href={p.imgref} target="_blank" rel="noreferrer">
                  <img
                    src={p.imgurl}
                    alt={t("references")}
                    title={t("open")}
                    className="m-auto max-h-[100vh]"
                  />
                </a>
              </div>
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  ) : null;
}
