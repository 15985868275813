export default function useLaptopImgs() {
  return [
    { image: "chatapp.webp" },
    { image: "curriculite.webp" },
    { image: "food.webp" },
    { image: "goldatti.webp" },
    { image: "interft.webp" },
    { image: "lawyer.webp" },
    { image: "sparkleapi.webp" },
    { image: "travel.webp" },
  ];
}
