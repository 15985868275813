import { useTranslation } from "react-i18next";

export default function useProgressData() {
  const { t } = useTranslation();

  return [
    {
      n: 1,
      icon: "ClipboardDocumentListIcon",
      header: t("requirements"),
      paragraph: t("requirements_paragraph"),
      pos: "bottom",
    },
    {
      n: 2,
      icon: "PaintBrushIcon",
      header: t("design_plan"),
      paragraph: t("design_plan_paragraph"),
      pos: "top",
    },
    {
      n: 3,
      icon: "CodeBracketSquareIcon",
      header: t("developments"),
      paragraph: t("developments_paragraph"),
      pos: "bottom",
    },
    {
      n: 4,
      icon: "ChatBubbleLeftRightIcon",
      header: t("communication"),
      paragraph: t("communication_paragraph"),
      pos: "top",
    },
    {
      n: 5,
      icon: "CheckBadgeIcon",
      header: t("finalize"),
      paragraph: t("finalize_paragraph"),
      pos: "bottom",
    },
  ];
}
