import useTitleText from "../data/useTitleTexts";
import { TypeAnimation } from "react-type-animation";

export default function AnimatedTitle(props) {
  const titleTexts = useTitleText();
  const animatedTextComponent = (
    <TypeAnimation
      sequence={titleTexts}
      wrapper="h1"
      speed={40}
      preRenderFirstString={true}
      className="mobile:text-smh1 text-h1size header leading-[100%] font-bold mobile:mt-2.5 mobile:mb-4 my-2 tablet:text-center"
      repeat={Infinity}
      key={Date.now()}
    />
  );

  return <>{animatedTextComponent}</>;
}
