import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./animation.module.css";

export default function Animation(props) {
  const { ref, inView } = useInView();
  const [counter, setCounter] = useState(false);
  const classNameHidden = "animation_" + props.direction + "_hidden";
  const classNameShow = "animation_" + props.direction + "_show";

  useEffect(() => {
    if (inView) setCounter(1);
  }, [inView]);

  return (
    <div
      ref={ref}
      style={{ transitionDelay: (props.delay ? props.delay : 0) + "ms" }}
      className={`${styles.animation_base} ${styles[classNameHidden]} ${
        counter < 1
          ? inView
            ? " " + styles[classNameShow]
            : ""
          : " " + styles[classNameShow]
      } ${props.className}`}
    >
      {props.children}
    </div>
  );
}
