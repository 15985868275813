import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Autoplay,
  Pagination,
  Keyboard,
  Navigation,
} from "swiper/modules";

import ServiceCard from "../../components/ServiceCard";
import Animation from "../../animation/Animation";
import useServices from "../../data/useServices";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

export default function Services() {
  const { t } = useTranslation();
  const service = useServices();

  const [height, setHeight] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setHeight("!h-full");
    }, 300);
  }, []);

  return (
    <section className="bg-p50 border-y border-s100">
      <Animation direction="bottom">
        <h2 className="mobile:text-smh2 text-h2size paragraph text-center font-bold mb-[60px] mt-[50px]">
          {t("services")}
        </h2>
      </Animation>
      <Animation direction="bottom">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          slidesPerView={1}
          spaceBetween={40}
          centeredSlides={true}
          navigation={true}
          autoHeight={true}
          keyboard={{
            enabled: true,
          }}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 10,
            depth: 50,
            modifier: 1,
            slideShadows: false,
          }}
          breakpoints={{
            650: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            1000: { slidesPerView: 3, spaceBetween: 50 },
            1250: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            2200: {
              slidesPerView: 6,
              spaceBetween: 50,
            },
            2500: {
              slidesPerView: 7,
              spaceBetween: 50,
            },
          }}
          modules={[
            Keyboard,
            EffectCoverflow,
            Autoplay,
            Pagination,
            Navigation,
          ]}
          className="swiperxl:container swiperxl:mx-auto mb-[50px]"
        >
          {service.map((data, i) => (
            <SwiperSlide key={i} className={height}>
              {({ isActive }) => (
                <ServiceCard
                  active={isActive ? "ring ring-4 ring-p500" : "ring-0"}
                  imgurl={data.imgurl}
                  imgalt={data.imgalt}
                  imgtitle={data.imgtitle}
                  header={data.header}
                  paragraph={data.paragraph}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Animation>
    </section>
  );
}
