import Animation from "../animation/Animation";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export default function SuccessNotification(props) {
  const { t } = useTranslation();
  const [notification, setNotification] = useState(true);

  const renderIcon = () => {
    if (props.status === "success") {
      return <CheckCircleIcon className="h-6 w-6 fill-c300" />;
    }
    return <XCircleIcon className="h-6 w-6 fill-w300" />;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Transition
        show={notification}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-10 top-5 right-5 fixed"
      >
        <Animation direction="right" className="z-10 top-5 right-5 fixed">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-n0 rounded-lg border-2 border-p200 shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 text-green-500 bg-green-100 rounded-lg">
              {renderIcon()}
            </div>
            <div className="ms-3 text-sm font-normal">
              {props.status === "success"
                ? t("quote_sent_successfully")
                : t("error")}
            </div>
            <button
              status="button"
              className="ms-auto -mx-1.5 -my-1.5 bg-n0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
              onClick={() => setNotification(!notification)}
            >
              <span className="sr-only">{t("close")}</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </Animation>
      </Transition>
    </>
  );
}
