import { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useInfobarLangs from "../../data/useInfoBarLangs";
import useInfobarSocials from "../../data/useInfoBarSocials";
import SocialItem from "../../components/SocialItem";
import EmailContact from "../../components/EmailContact";

export default function Infobar() {
  const { i18n } = useTranslation();
  const langs = useInfobarLangs();
  const socials = useInfobarSocials();
  const [langLabel, setLangLabel] = useState(langs[0].label);

  useEffect(() => {
    if (localStorage.getItem("lang") != null) {
      i18n.changeLanguage(localStorage.getItem("lang"));
      setLangLabel(
        langs[
          langs.findIndex((lang) => lang.value === localStorage.getItem("lang"))
        ].label
      );
    }
  }, [i18n]); // eslint-disable-line

  function handleClick(n) {
    setLangLabel(langs[n].label);
    i18n.changeLanguage(langs[n].value);
    localStorage.setItem("lang", langs[n].value);
  }

  return (
    <>
      <div className="flex flex-row justify-between bg-n900 text-n0">
        <EmailContact />
        <div className="flex flex-row items-center mx-4 mt-1">
          <div className="mt-2">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="inline-flex">{langLabel}</Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 absolute right-0 mt-2 w-32 divide-y divide-n0 rounded-md bg-n0 ring-2 ring-black">
                  <div className="py-1">
                    <Menu.Item onClick={() => handleClick(0)}>
                      {({ active }) => (
                        <button
                          href="#"
                          className={classNames(
                            active ? "bg-p50 text-n700" : "text-n900",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          {langs[0].label}
                          {langs[0].name}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleClick(1)}>
                      {({ active }) => (
                        <button
                          href="#"
                          className={classNames(
                            active ? "bg-p50 text-n700" : "text-n900",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          {langs[1].label}
                          {langs[1].name}
                        </button>
                      )}
                    </Menu.Item>
                    {/* <Menu.Item onClick={() => handleClick(2)}>
                      {({ active }) => (
                        <button
                          href="#"
                          className={classNames(
                            active ? "bg-p50 text-n700" : "text-n900",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          {langs[2].label}
                          {langs[2].name}
                        </button>
                      )}
                    </Menu.Item> */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="hidden md:flex flex-row md:mr-10">
            {socials.map((data, i) => {
              return (
                <SocialItem
                  key={i}
                  href={data.href}
                  src={data.src}
                  title={data.title}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
