import { useTranslation } from "react-i18next";

export default function useServices() {
  const { t } = useTranslation();

  return [
    {
      imgurl: "/images/greetings.webp",
      imgalt: "greetings",
      imgtitle: t("website_desing_development"),
      header: t("website_desing_development"),
      paragraph: t("website_desing_development_paragraph"),
    },
    {
      imgurl: "/images/rule.webp",
      imgalt: "rule",
      imgtitle: t("responsive_design"),
      header: t("responsive_design"),
      paragraph: t("responsive_design_paragraph"),
    },
    {
      imgurl: "/images/statistics.webp",
      imgalt: "statistics",
      imgtitle: t("SEO"),
      header: t("SEO"),
      paragraph: t("SEO_paragraph"),
    },
    {
      imgurl: "/images/programming.webp",
      imgalt: "programming",
      imgtitle: t("Webapp_development"),
      header: t("Webapp_development"),
      paragraph: t("Webapp_development_paragraph"),
    },
    {
      imgurl: "/images/brush.webp",
      imgalt: "brush",
      imgtitle: t("UI_UX"),
      header: t("UI_UX"),
      paragraph: t("UI_UX_paragraph"),
    },
    {
      imgurl: "/images/padlock.webp",
      imgalt: "padlock",
      imgtitle: t("backup_services"),
      header: t("backup_services"),
      paragraph: t("backup_services_paragraph"),
    },
  ];
}
