import { useTranslation } from "react-i18next";
import ProgressCard from "../../components/ProgressCard";
import Animation from "../../animation/Animation";
import useProgressData from "../../data/useProgressData";

export default function Progress() {
  const { t } = useTranslation();
  const data = useProgressData();

  const selectedTopElements = [data[0], data[2], data[4]];
  const selectedBottomElements = [data[1], data[3]];

  return (
    <section>
      <div className="tablet:mt-[100px] bg-p50 border-y border-s100">
        <Animation direction="bottom">
          <h2 className="mobile:text-smh2 text-h2size header text-center font-bold mb-[60px] mt-[50px]">
            {t("progress")}
          </h2>
        </Animation>
        <div className="px-[80px] 2xl:max-w-[2840px] mx-auto tablet:hidden">
          <Animation direction="bottom">
            <div className="flex gap-8">
              {selectedTopElements.map((p, i) => (
                <ProgressCard
                  key={i}
                  n={p.n}
                  paragraph={p.paragraph}
                  header={p.header}
                  lineDirection={p.pos}
                  icon={p.icon}
                  styleheight={"smbook:h-[200px] mlg:h-[250px]"}
                  stylewidth={"progress:min-w-[60px]"}
                />
              ))}
            </div>
          </Animation>
          <Animation direction="left">
            <div className="flex max-h-[2px] items-center">
              <div className="h-[2px] w-full bg-s300 self-center"></div>
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 6L-2.06713e-07 0.226497L8.02759e-07 11.7735L10 6ZM8.74228e-08 7L1 7L1 5L-8.74228e-08 5L8.74228e-08 7Z"
                  fill="#3737D5"
                />
              </svg>
            </div>
          </Animation>

          <Animation direction="bottom">
            <div className="flex gap-8 justify-center mb-[60px]">
              {selectedBottomElements.map((p, i) => (
                <ProgressCard
                  key={i}
                  n={p.n}
                  paragraph={p.paragraph}
                  header={p.header}
                  lineDirection={p.pos}
                  icon={p.icon}
                  styleheight={"smbook:h-[200px] mlg:h-[250px]"}
                  stylewidth={"progress:min-w-[60px]"}
                />
              ))}
            </div>
          </Animation>
        </div>
        <div className="max-w-[980px] mx-auto flex flex-col gap-4 mb-[60px] tabletmin:hidden">
          {data.map((p, i) => (
            <Animation direction="bottom" key={i}>
              <ProgressCard
                n={p.n}
                paragraph={p.paragraph}
                header={p.header}
                icon={p.icon}
                className={"w-full px-[10px]"}
                styleheight={"progressmin:h-[230px]"}
              />
            </Animation>
          ))}
        </div>
      </div>
    </section>
  );
}
