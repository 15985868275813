import { useTranslation } from "react-i18next";

export default function useTitleTexts() {
  const { t } = useTranslation();

  return [
    t("title"),
    1000,
    t("title_animation_1"),
    1000,
    t("title_animation_2"),
    1000,
    t("title_animation_4"),
    1000,
    t("title_animation_5"),
    1000,
    t("title_animation_6"),
    1000,
    t("title_animation_7"),
    1000,
    t("title_animation_8"),
    1000,
  ];
}
